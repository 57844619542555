import Img from "gatsby-image";
import React from "react";
import ReactMarkdown from "react-markdown";

const TeamCards = React.lazy(() => import("../TeamCards"));

function FeaturedImageSection({ section }) {
  const isSSR = typeof window === "undefined";

  let isDarkTheme =
    section.background_color === `gladior-black` ||
    section.background_color === `gladior-dark`;

  let imagePanel = (
    <div className="mt-8 lg:mt-0" key="image-panel">
      <div className="lg:ml-auto lg:max-w-xl flex justify-center">
        <Img
          alt={section.header}
          className="w-full"
          fluid={section.image.childImageSharp.fluid}
          title={section.header}
        />
      </div>
    </div>
  );

  let contentPanel = (
    <div
      className={
        section.image_location === `left`
          ? `mt-8 lg:mt-0`
          : section.image_location === `right` &&
            section.sub_header === `Ons Team`
          ? `mb-10 md:mb-0`
          : ``
      }
      key="contentPanel"
    >
      {section.sub_header && (
        <p
          className={`text-base leading-6 ${
            section.featured_color === `default`
              ? `text-gray-300`
              : `text-${
                  section.featured_color === `gladior-yellow` &&
                  isDarkTheme === false
                    ? `text-gray-300`
                    : section.featured_color
                }`
          } font-medium `}
        >
          {section.sub_header}
        </p>
      )}
      {section.header && (
        <h2
          className={`text-3xl leading-12 font-semibold font-display ${
            isDarkTheme === true ? `text-gladior-white` : `text-gray-900`
          } `}
        >
          {section.header}
        </h2>
      )}
      {section.subtitle && (
        <p
          className={`text-lg  ${
            isDarkTheme === true ? `text-gray-300` : `text-gray-900`
          }  sm:text-xl sm:max-w-xl  xl:text-xl xl:max-w-xl leading-12 mt-4 mb-4`}
        >
          {section.subtitle}
        </p>
      )}

      {section.description && (
        <ReactMarkdown
          className={`mt-6 text-base leading-6  ${
            isDarkTheme === true ? `text-gray-300` : `text-gray-700`
          } max-w-3xl prose prose-lg  `}
          source={section.description}
        />
      )}
      {section.cta && (
        <>
          {section.cta.map((link) => {
            return (
              <div className="py-4 mt-3" key={link}>
                <a
                  className=" mt-4  h-12 sm:mt-0 sm:h-auto  px-6 py-3 font-semibold leading-snug bg-white text-gladior-black uppercase tracking-wide rounded-lg shadow-md focus:outline-none focus:ring hover:bg-gladior-pink"
                  href={link.href}
                >
                  {link.text}
                </a>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
  let panels =
    section.image_location === `left`
      ? [imagePanel, contentPanel]
      : [contentPanel, imagePanel];

  if (section.type === `featured_image` && section.sub_header === `Ons Team`) {
    panels = [
      contentPanel,
      <React.Fragment key="team">
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <TeamCards />
          </React.Suspense>
        )}
      </React.Fragment>,
    ];
  }

  return (
    <section
      className={`max-w-8xl mx-auto  px-4 sm:px-6 lg:px-8 py-12  overflow-hidden bg-${section.background_color}`}
      key={section.header}
    >
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8 ">
        <div className="lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center ">
          {panels}
        </div>
      </div>
    </section>
  );
}

export default FeaturedImageSection;
