import React from "react";
import BandMemberSmall from "./BandMemberSmall";

function HeaderIntroduction({ service }) {
  return (
    <div className="px-6 text-left md:text-center xl:text-left max-w-2xl md:max-w-3xl mx-auto">
      <h1 className="text-3xl tracking-tight sm:text-4xl md:text-5xl xl:text-5xl font-medium leading-tight text-gladior-white">
        {service.title}
      </h1>

      <p className="mt-6 leading-relaxed sm:text-lg md:text-xl xl:text-lg text-gray-300">
        {service.lead_paragraph}
      </p>
      <div className="mt-6 sm:flex sm:mt-8 xl:mt-12 justify-start md:justify-center xl:justify-start">
        <div className="">
          <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">
            The band members
          </p>
          <div className="mt-4 sm:flex">
            {service.members.map((member, index) => {
              return (
                <BandMemberSmall
                  index={index}
                  key={member.full_name}
                  member={member}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderIntroduction;
