import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";

function ServiceCard({ item }) {
  if (!item) {
    return undefined;
  }
  return (
    <div className="flex flex-col  items-center justify-center w-full ">
      <div className="p-4 flex flex-col justify-between leading-normal">
        <div className="flex flex-row justify-center">
          {item.thumbnail && item.thumbnail.childImageSharp && (
            <Img
              alt={item.id}
              fixed={item.thumbnail.childImageSharp.fixed}
              title={item.id}
            />
          )}
        </div>
        <div className="mb-8">
          <div className="text-white font-bold text-xl mb-2 ">{item.title}</div>
          <p className="text-base leading-6  text-gray-300 max-w-3xl prose prose-lg ">
            {item.caption}
          </p>
        </div>
        <div className="flex items-center">
          <Link
            className="bg-transparent hover:bg-gladior-pink  text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
            title={item.title}
            to={item.fields.slug}
          >
            Meer informatie
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
