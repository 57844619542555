import Img from "gatsby-image";
import React from "react";
import SVG from "react-inlinesvg";
import ReactMarkdown from "react-markdown";

function FeaturedSectionsSection({ section }) {
  let isDarkTheme =
    section.background_color === `gladior-black` ||
    section.background_color === `gladior-dark`;

  let sectionPanel;

  if (section.image_location === `grid-2x2`) {
    sectionPanel = (
      <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-16">
        {section.section_items.map((sItem, si) => {
          return (
            <li className={si === 0 ? `` : `mt-10 md:mt-0`} key={si}>
              <div className="flex">
                <div className="flex-shrink-0">
                  <Img fixed={sItem.icon.childImageSharp.fixed} />

                  {!sItem.icon.childImageSharp &&
                    sItem.icon.extension === `svg` && (
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gladior-pink text-gladior-white">
                        <SVG
                          className="h-6 w-6"
                          fill="#fff"
                          preProcessor={(code) =>
                            code.replace(/fill=".*?"/g, `fill="#fff"`)
                          }
                          src={sItem.icon.publicURL}
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        />
                      </div>
                    )}
                </div>
                <div className="ml-4">
                  {sItem.header && (
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      {sItem.header}
                    </h4>
                  )}

                  {sItem.description && (
                    <ReactMarkdown
                      className="mt-2 text-base leading-6 text-gray-700 prose prose-lg"
                      source={sItem.description}
                    />
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else if (section.image_location === `three-column`) {
    sectionPanel = (
      <div className="mt-8 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 lg:mt-16">
        {section.section_items.map((sItem, si) => {
          return (
            <div className="flex items-start" key={si}>
              <div className="ml-0  md:ml-4">
                {sItem.chip && (
                  <span className="inline-block bg-gray-200 text-gray-900 text-xs px-2 rounded-full uppercase font-semibold tracking-wide leading-6 mb-4">
                    {sItem.chip}
                  </span>
                )}
                {sItem.header && (
                  <h4
                    className={`text-lg leading-6 font-medium  ${
                      isDarkTheme === true
                        ? `text-gladior-white`
                        : `text-gray-900`
                    }`}
                  >
                    {sItem.header}
                  </h4>
                )}
                {sItem.description && (
                  <p
                    className={`mt-2 text-base leading-6 ${
                      isDarkTheme === true ? `text-gray-300` : `text-gray-700 `
                    } `}
                  >
                    {sItem.description}
                  </p>
                )}

                <div className="mt-4">
                  <a
                    className={`text-sm ${
                      isDarkTheme === true
                        ? `text-gladior-white`
                        : `text-gladior-pink`
                    } font-medium hover:underline`}
                    href={sItem.link.href}
                  >
                    {sItem.link.text}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <section
      className={`max-w-8xl mx-auto  px-4 sm:px-6 lg:px-8 py-12 bg-${section.background_color}`}
      key={section.header}
    >
      <div className="container mx-auto px-0 md:px-8">
        <div className="py-12">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              {section.sub_header && (
                <p
                  className={`text-base leading-6 ${
                    section.featured_color === `default`
                      ? `text-gray-300`
                      : `text-${
                          section.featured_color === `gladior-yellow` &&
                          isDarkTheme === false
                            ? `text-gray-300`
                            : section.featured_color
                        }`
                  } font-medium`}
                >
                  {section.sub_header}
                </p>
              )}

              {section.header && (
                <h3
                  className={`mt-2 text-3xl leading-8 font-extrabold tracking-tight ${
                    isDarkTheme === true
                      ? `text-gladior-white`
                      : `text-gray-900`
                  } sm:text-4xl sm:leading-10`}
                >
                  {section.header}
                </h3>
              )}

              {section.description && (
                <ReactMarkdown
                  className="mt-10 max-w-2xl text-base leading-12 text-gray-700 lg:mx-auto prose prose-lg"
                  source={section.description}
                />
              )}
            </div>
            <div className="mt-20 max-w-5xl flex justify-center mx-auto ">
              {sectionPanel}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedSectionsSection;
