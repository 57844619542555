import Img from "gatsby-image";
import React from "react";

function BandMemberSmall({ index, member }) {
  return (
    <div
      className={
        index === 0
          ? `flex items-center no-underline`
          : `mt-6 sm:mt-0 sm:ml-12 flex items-center no-underline`
      }
    >
      <div className="flex-shrink-0">
        <Img
          alt={"Foto van " + member.full_name}
          className="h-12 w-12 rounded-full border-2 border-white"
          fluid={member.image.childImageSharp.fluid}
          title={member.full_name}
        />
      </div>
      <div className="ml-3">
        <p className="font-semibold text-white leading-tight">
          {member.full_name}
        </p>
        <p className="text-sm text-gray-500 leading-tight">{member.function}</p>
      </div>
    </div>
  );
}

export default BandMemberSmall;
