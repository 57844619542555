import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React from "react";
import Particles from "react-particles-js";
import { useMediaQuery } from "react-responsive";

function ParticlesHeader({ title }) {
  const data = useStaticQuery(
    graphql`
      query {
        computerUser: file(relativePath: { eq: "computer-user.png" }) {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }

        computerUserMobile: file(relativePath: { eq: "computer-user.png" }) {
          childImageSharp {
            fixed(height: 300) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }

        backgroundData: file(
          relativePath: { eq: "background-dasboarding.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  const sources = [
    data.computerUserMobile.childImageSharp.fixed,
    {
      ...data.computerUser.childImageSharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <div className="max-w-4xl lg:ml-auto lg:max-w-4xl mx-auto overflow-hidden">
      <BackgroundImage
        Tag="section"
        fluid={data.backgroundData.childImageSharp.fluid}
        style={{
          height: isDesktopOrLaptop ? `400px` : `300px`,
          boxShadow: `inset 0 0 100px #20252c`,
        }}
      >
        <div className="relative h-full flex flex-col justify-end items-center">
          <Particles
            className=" absolute top-0 left-0 h-full w-full "
            params={{
              particles: {
                number: {
                  value: 20,
                  density: {
                    enable: true,
                    value_area: 100,
                  },
                },
                line_linked: {
                  enable: false,
                },
                move: {
                  speed: 2,
                  direction: `none`,
                  out_mode: `out`,
                },
                opacity: {
                  value: 1,
                },
                shape: {
                  type: [`image`],
                  image: [
                    {
                      src: `/images/dash-1.png`,
                      height: 20,
                      width: 20,
                    },

                    {
                      src: `/images/dash-4.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-5.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-6.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-7.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-8.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-9.png`,
                      height: 20,
                      width: 20,
                    },

                    {
                      src: `/images/dash-11.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-12.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-13.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-14.png`,
                      height: 20,
                      width: 40,
                    },
                    {
                      src: `/images/dash-15.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-16.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-17.png`,
                      height: 20,
                      width: 20,
                    },
                    {
                      src: `/images/dash-18.png`,
                      height: 20,
                      width: 20,
                    },
                  ],
                },
                color: {
                  value: `#fff`,
                },
                size: {
                  value: 15,
                  random: false,
                  anim: {
                    enable: true,
                    speed: 4,
                    size_min: 10,
                    sync: false,
                  },
                },
              },
              retina_detect: false,
            }}
          />

          <Img alt={title} fixed={sources} title={title} />
        </div>
      </BackgroundImage>
    </div>
  );
}

export default ParticlesHeader;
