import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import Header from "../components/header";
import ParticlesHeader from "../components/ParticlesHeader";
import SEO from "../components/seo";
import FeaturedImageSection from "../components/services/FeaturedImageSection";
import FeaturedSectionsSection from "../components/services/FeaturedSectionsSection";
import HeaderIntroduction from "../components/services/HeaderIntroduction";
import ServiceCard from "../components/services/ServiceCard";

function generateBreadcrumbs(data) {
  const { serviceYaml, allServiceCategoryYaml } = data;
  let breadcrumbs = [];

  breadcrumbs.push({
    name: `Services`,
    slug: `/services/`,
  });

  if (allServiceCategoryYaml && allServiceCategoryYaml.edges.length > 0) {
    let node = allServiceCategoryYaml.edges[0].node;
    breadcrumbs.push({
      name: node.id,
      slug: node.fields.slug,
    });
  }

  breadcrumbs.push({
    name: serviceYaml.id,
  });

  return breadcrumbs;
}

const ServicePage = ({ data }) => {
  const {
    serviceYaml: post,
    backgroundData,
    computerUser,
    computerUserMobile,
  } = data;

  const breadcrumbs = generateBreadcrumbs(data);

  return (
    <>
      <SEO
        description={post.seo.meta_description}
        pathname={post.path}
        title={post.seo.meta_title}
      />
      <div className="flex flex-col font-sans min-h-screen text-gray-900  bg-gladior-black">
        <Header absolute={false} darkBackground={true} />
        <main className="bg-gladior-black ">
          <section className="w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 ">
            <div className="container mx-auto px-0 md:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center my-8">
                <div className="mt-8 lg:mt-0 ">
                  <ParticlesHeader title={post.title} />
                </div>
                <div className="mt-12 xl:mt-0">
                  <HeaderIntroduction service={post} />
                </div>
              </div>
            </div>
          </section>

          {post.service_item_categories && (
            <section className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8  bg-gladior-black py-10 ">
              <div className="container mx-auto px-0 md:px-8">
                <div className="mt-12 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                  {post.service_item_categories.map((item) => {
                    return <ServiceCard item={item} key={item.id} />;
                  })}
                </div>
              </div>
            </section>
          )}

          {post.sections.map((section, index) => {
            if (section.type === `featured_image`) {
              return (
                <FeaturedImageSection
                  key={`content-panel-${index}`}
                  section={section}
                />
              );
            } else if (section.type === `featured_sections`) {
              return (
                <FeaturedSectionsSection
                  key={`content-panel-${index}`}
                  section={section}
                />
              );
            } else {
              return null;
            }
          })}

          <section className="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:px-8 bg-gladior-dark ">
            <div className="container mx-auto">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          </section>
        </main>
        <Footer isDark={true} isDarker={true} />
      </div>
    </>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicePage;

export const servicePageQuery = graphql`
  query ServiceAdvancedPage($id: String!) {
    computerUser: file(relativePath: { eq: "computer-user.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    computerUserMobile: file(relativePath: { eq: "computer-user.png" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    backgroundData: file(relativePath: { eq: "background-dasboarding.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    allServiceCategoryYaml(
      filter: { services: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
      }
    }
    serviceYaml(id: { eq: $id }) {
      id
      description
      caption
      fields {
        slug
      }
      title
      lead_paragraph
      members {
        full_name
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 50) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        function
      }
      sections {
        background_color
        cta {
          color
          href
          size
          text
          variant
        }
        description
        featured_color
        header
        image_location
        section_items {
          description
          header
          icon {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              fixed(height: 600) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
            extension
            publicURL
          }
        }
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(height: 600) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        sub_header
        subtitle
        type
      }
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      seo {
        meta_title
        meta_description
      }
      service_item_categories {
        id
        thumbnail {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        background {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        caption
        title
        fields {
          slug
        }
      }
      related_cases {
        about_customer
        body
        description
        id
        lead_paragraph
        quote {
          organisation
          person
          quote
        }
        seo {
          meta_description
          meta_title
        }
        services
        subtitle
        templateKey
        title
        fields {
          slug
        }
        cover_image {
          childImageSharp {
            fixed(height: 256) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      thumbnail {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  }
`;
